<template>
  <div v-if="!consentGiven" class="cookie-consent">
    <p>
      This website uses cookies to enhance the user experience. By continuing to
      browse the site you are agreeing to our use of cookies.
    </p>
    <button @click="acceptCookies">Accept</button>
    <button @click="rejectCookies">Reject</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const consentGiven = ref(false);

const checkConsent = () => {
  const cookiesAccepted = document.cookie.split(';').some((item) => item.trim().startsWith('cookies_accepted='));
  const cookiesRejected = document.cookie.split(';').some((item) => item.trim().startsWith('cookies_rejected='));

  if (cookiesAccepted || cookiesRejected) {
    consentGiven.value = true;
  }
};

const acceptCookies = () => {
  document.cookie = "cookies_accepted=true; path=/; max-age=" + (365*24*60*60);
  consentGiven.value = true;
};

const rejectCookies = () => {
  document.cookie = "cookies_rejected=true; path=/; max-age=" + (365*24*60*60);
  consentGiven.value = true;
  removeNonEssentialCookies();
};

const removeNonEssentialCookies = () => {
  // List all the non-essential cookies that you set
  const nonEssentialCookies = ['cookie_name_1', 'cookie_name_2', 'cookie_name_3'];

  nonEssentialCookies.forEach(cookie => {
    document.cookie = `${cookie}=; path=/; max-age=0;`;
  });
};

onMounted(() => {
  checkConsent();
});
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #333;
  color: white;
  padding: 1em;
  text-align: center;
  z-index: 1000;
}

.cookie-consent button {
  margin: 0 0.5em;
}
</style>
