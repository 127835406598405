<template>
  <div v-if="!isVerified" class="age-verification text-black">
    <h2>Age Verification</h2>
    <p>This website contains adult content and is only suitable for those 18 years or older. By entering this site, you confirm that you are at least 18 years of age.</p>
    <button class="text-red-700" @click="verifyAge">I am 18 or older</button>
    <button class="text-red-700" @click="redirectToSafeSite">I am under 18</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVerified: false
    }
  },
  mounted() {
    this.checkAgeVerification()
  },
  methods: {
    verifyAge() {
      localStorage.setItem('ageVerified', true)
      this.isVerified = true;
      this.$emit('verified');
    },
    redirectToSafeSite() {
      window.location.href = 'https://www.google.com'
    },
    checkAgeVerification() {
      const verified = localStorage.getItem('ageVerified')
      if (verified) {
        this.isVerified = true
      }
    }
  }
}
</script>

<style scoped>
.age-verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #fff;
}
</style>
