<template>
  <div class="bg-neutral-900 text-neutral-200">
    <AgeVerification v-if="!isVerified" @verified="handleVerified" />
    <div v-else class="flex flex-row">
      <NavBar />
      <div class="basis-full min-h-screen">
        <slot />
      </div>
    </div>
    <footer class="mt-20">
      <p class="text-center">&copy; 2024 Myra Calendars. All rights reserved.</p>
      <nuxt-link to="/tos"><p class="text-center">Terms of Service</p></nuxt-link>
      <nuxt-link to="/privacy-policy"><p class="text-center">Privacy Policy</p></nuxt-link>
    </footer>
    <CookieConsent />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '~/stores/useAuthStore';
import AgeVerification from '~/components/AgeVerification.vue';
import CookieConsent from '~/components/CookieConsent.vue';

const router = useRouter();
const authStore = useAuthStore();

const isVerified = ref(false);

const checkAgeVerification = () => {
  const verified = localStorage.getItem('ageVerified');
  if (verified) {
    isVerified.value = true;
  }
};

const handleVerified = () => {
  console.log('Age verification successful');
  isVerified.value = true;
};
const auth = useAuthStore();

async function getUser() {
  await auth.fetchUser();

}

onMounted(() => {
  checkAgeVerification();
  authStore.fetchUser();
  // Check if an access token is already stored
  const storedAccessToken = sessionStorage.getItem('patreonAccessToken');

  if (storedAccessToken) {
    authStore.patreonAccessToken = storedAccessToken;

    // Optionally, fetch user data using the stored token
    authStore.fetchPatreonUserData(storedAccessToken); // Implement this if needed
  }
});
</script>

<style scoped>
/* Add any global styles here */
</style>
